<template>
  <div>
    <b-img
      src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Ffreestocks-A11MXTzUhLE-unsplash.jpg?alt=media&token=50fc355d-f521-4db2-9175-fecb3cfce1c6"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    />
    <div class="title_ text-center mt-3">
      よくある質問
    </div>
    <div class="faqSpace">
      <AllFAQ />
    </div>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import AllFAQ from '@/components/basic/faq/AllFAQ.vue'

export default {
  components: {
    BImg,
    AllFAQ,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 110%;
  height: 360px;
  object-fit: cover;
  margin: -5% -5% 0 -5%;
}
.title_ {
  font-size: 3.2rem;
  font-weight: 600;
}
.faqSpace {
  width: 70%;
  margin: 0 auto;
}
</style>
