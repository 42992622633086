<template>
  <div class="pricing-faq">
    <!-- <h3 class="text-center">
      よくある質問
    </h3> -->
    <!-- <p class="text-center">
      テキストテキストテキストテキストテキストテキストテキストテキスト
    </p> -->
    <b-row class="py-2">
      <b-col
        class="mx-auto"
      >
        <app-collapse
          accordion
          type="margin"
        >
          <app-collapse-item
            v-for="(data,index) in FAQ"
            :key="index"
            :title="data.title"
          >
            <div class="ml-2">
              <div class="">
                {{ data.text1 }}
              </div>
              <div
                v-if="data.text2"
                class="mt-1"
              >
                {{ data.text2 }}
              </div>
              <div
                v-if="data.text3"
                class="mt-1"
              >
                {{ data.text3 }}
              </div>
              <div
                v-if="data.text4"
                class="mt-1"
              >
                {{ data.text4 }}
              </div>
              <div
                v-if="data.text5"
                class="mt-1"
              >
                {{ data.text5 }}
              </div>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FAQ from '@/components/basic/faq/よくある質問.json'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BCol, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BCol,
    BRow,
    // BButton,
    // BCard,
    // BImg,
  },
  directives: {
  },
  props: {
    sample: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      FAQ,
    }
  },
  watch: {
  },
  methods: {
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
<style scoped>

</style>
