<template>
  <b-row class="match-height">
    <b-col lg="12">
      <FAQBasic class="mb-3" />
      <Footer />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import Footer from '@/components/basic/footer/Footer.vue'
import FAQBasic from '@/components/basic/about/FAQBasic.vue'

export default {
  components: {
    BRow,
    BCol,
    FAQBasic,
    Footer,
  },
}
</script>
